<template>
  <div>
    <div
      class="modal fade"
      id="modal-form-cambio-transportadora"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Cambio Transportadora</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="">Transportadora Actual</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id=""
                    readonly
                    v-model="trans"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="">Transportadora Sustituye</label>
                  <v-select
                    :class="[
                      $v.form.transportadora_id.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="transportadora"
                    placeholder="Transportadora"
                    label="razon_social"
                    class="form-control form-control-sm p-0"
                    :options="listasForms.transportadoras"
                    @search="buscarTransportadoras"
                    @input="selectTransportadora()"
                  ></v-select>
                  <div class="error" v-if="!$v.form.transportadora_id.required">
                    Ingrese una Trnasportadora
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="!$v.form.$invalid"
              @click="save()"
            >
              Realizar Cambio
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";
export default {
  name: "TepViajeTransportadora",
  components: {
    vSelect,
  },
  data() {
    return {
      form_viaje: {},
			trans: null,
      form: {
        transportadora_id: null,
      },
      transportadora: [],
      listasForms: {
        transportadoras: [],
      },
    };
  },
  validations: {
    form: {
      transportadora_id: {
        required,
      },
    },
  },
  methods: {
    llenar_modal(viaje) {      
      this.form_viaje = viaje;
			this.trans = this.form_viaje.transportadora.razon_social;

    },

    async buscarTransportadoras() {
      let me = this;
      me.listasForms.transportadoras = [];
      var url = "api/admin/empresas/lista?estado=1";
      await axios
        .get(url, {
          params: {
            linea_negocio_id: 5,
          },
        })
        .then(function(response) {
          me.listasForms.transportadoras = response.data;
        })
        .catch(function(e) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    selectTransportadora() {
      this.form.transportadora_id = null;
      if (this.transportadora) {
        this.form.transportadora_id = this.transportadora.id;
      }
    },

    save() {
      this.form.viaje_id = this.form_viaje.id;
      if (!this.$v.form.$invalid) {
        axios({
          method: "PUT",
          url: "/api/tep/viajes/cambioTransportadora",
          data: this.form,
        })
          .then((response) => {
            this.form = { transportadora_id: null, id: null };
            this.transportadora = [];
            this.$refs.closeModal.click();
            this.$swal({
              icon: "success",
              title: "Se cambio la transportadora.",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
        this.$parent.getIndex();
      }
    },
  },
};
</script>
