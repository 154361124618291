<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="modal-form-cancelar-viaje">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Cancelar Viaje</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-12">
                <label for="fecha_fin">Razón Cancelación</label>
                <textarea 
                  v-model="form.razon"
                  style="font-size: 13px"
                  class="form-control form-control-sm"
                  :class="
                    $v.form.razon.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                ></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="!$v.form.$invalid"
              @click="save()"
            >
              Cancelar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
export default {
  name: "TepViajeCancelar",
  data() {
    return {
      form: {},
    };
  },
  validations: {
    form: {
      razon: {
        required,
      },
    },
  },
  methods: {
    save() {
      this.$swal({
        title: "Está seguro de cancelar esta Solicitud?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Cancelar!",
      }).then((result) => {
        if (result.value) {
          axios({
            method: "PUT",
            url: "/api/tep/viajes/cancelar",
            data: this.form,
          }).then(() => {
            this.$parent.getIndex();
            this.$refs.closeModal.click();
            this.$swal({
              icon: "success",
              title: "Se canceló la Solicitud exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },

    llenar_modal(viaje) {
      this.form = {
        id: viaje.id,
        razon: viaje.razon_cancelacion,
      };
    },
  },
};
</script>
